import styled from 'styled-components'

/*
 * Base components for pages
 */

export const Container = styled.div`
  width: 100vw;
  min-height: fit-content;
  box-sizing: border-box;
  max-width: 100%;
  position: relative;

  & > * {
    max-width: 100%;
  }
`

export const MainContent = styled.div`
  padding: 24px;

  > * {
    &:not(:last-child) {
      :not(:empty) {
        margin-bottom: 32px;
      }
    }
  }
`
