import { BreadcrumbItem, BreadcrumbsProps } from '../../mfe/types/shell'
import { EDIT_SUFFIX } from 'constants/constant'

export const bcPolicies = 'policies'
export const bcPoliciesDetails = 'policies-details'
export const bcPoliciesEdit = 'policies-edit'

/**
 * Change breadcrumbs
 *
 * @param breadcrumbs interface
 * @param t localization
 * @param suffix sub-MFE url (optional)
 * @param baseurl baseurl url (optional, only if suffix is provided)
 */

export function changeBreadcrumbs(
  breadcrumbs: BreadcrumbsProps,
  t: (string) => string,
  suffix?: string,
  baseurl?: string
) {
  if (breadcrumbs && breadcrumbs.isEnabled()) {
    const bc = breadcrumbs.getBreadcrumbs()
    const index = bc.findIndex((x) => x.key === bcPolicies) + 1
    if (index > 0) {
      if (index < bc.length) {
        breadcrumbs.remove(bc[index].key)
      }

      // Next levels for Policies-Policies and Policies-Devices
      if (suffix) {
        // Details
        const items: BreadcrumbItem[] = [
          { key: bcPoliciesDetails, text: t('breadcrumbs.details') }
        ]

        if (suffix.startsWith(EDIT_SUFFIX)) {
          // Add url to Details item - it's always Policies, not Devices
          items[0].url = baseurl + suffix.slice(EDIT_SUFFIX.length)

          // Edit
          items.push({ key: bcPoliciesEdit, text: t('breadcrumbs.edit') })
        }

        // Add all items
        items.forEach((x) => breadcrumbs.add(x))
      }
    }
  }
}
