import React from 'react'
import PolicyHomeMain from 'components/PolicyHomeMain'
import ConfigProvider from 'context/config/ConfigProvider'
import projectNames from './configs/projectNames'
import { ShellProps } from './types/shell'
import { ThemeProvider, ToastProvider, useToast } from '@veneer/core'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

export default function Root(props) {
  const { v1 } = window.Shell as ShellProps
  const breadcrumbs = v1?.breadcrumbs
  const localization = {
    language: v1?.localization?.language || 'en',
    country: v1?.localization?.country || 'US'
  }
  const navigation = v1?.navigation
  const accessControl = v1?.accessControl
  const themeProviderProps = v1?.theme?.getThemeProviderProperties?.()
  const mfeProps = {
    breadcrumbs,
    navigation,
    accessControl
  }
  return (
    <section id={projectNames.packageJsonName}>
      <ThemeProvider {...themeProviderProps}>
        <ConfigProvider {...localization}>
          {props.useToast ? (
            <PolicyHomeMain {...props} {...mfeProps} />
          ) : (
            <ToastProvider>
              <PolicyHomeMain {...props} {...mfeProps} useToast={useToast} />
            </ToastProvider>
          )}
        </ConfigProvider>
      </ThemeProvider>
    </section>
  )
}
