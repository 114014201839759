export const POLICIES_PATH = '/policies'
export const POLICIES_MFE = '@jarvis/react-ecp-policies'
export const POLICIES_MFE_TYPE = 'PoliciesPolicies'

export const ASSIGNMENTS_SUFFIX = '/assignments'
export const ASSIGNMENTS_MFE = '@jarvis/react-ecp-tasks'
export const ASSIGNMENTS_MFE_TYPE = 'PoliciesAssignments'

export const DEVICES_SUFFIX = '/devices'
export const DEVICES_MFE = '@jarvis/react-ecp-policies-devices'
export const DEVICES_MFE_TYPE = 'PoliciesDevices'

export const EDIT_SUFFIX = '/edit'

export const HEADER_MFE = '@jarvis/react-ecp-header'
export const HEADER_MFE_TYPE = 'headerTitle'

export const TASK_READ_SCOPE = 'ws-hp.com/fleet/task.READ'
export const COMPLIANCE_READ_SCOPE = 'ws-hp.com/fleet/compliance.READ'
export const COLLECTION_READ_SCOPE = 'ws-hp.com/collections/collections.READ'

export const ASSIGNMENT_TAB_SCOPES = [
  { scope: TASK_READ_SCOPE },
  { scope: COLLECTION_READ_SCOPE }
]
export const DEVICE_TAB_SCOPES = [
  { scope: COMPLIANCE_READ_SCOPE },
  { scope: COLLECTION_READ_SCOPE }
]
export const DEVICE_DETAILS_SCOPES = [{ scope: COMPLIANCE_READ_SCOPE }]

export enum PoliciesTabsEnum {
  Policies = 0,
  Assignments,
  Devices
}
