import React, { useContext, useEffect } from 'react'
import { MicroFrontend } from 'components/MicroFrontend'
import ConfigContext from 'context/config/ConfigContext'
import { changeBreadcrumbs } from 'common/utilities'

const MfeWrapper = (props) => {
  const { t } = useContext(ConfigContext)
  const { baseurl, baseNameLength, breadcrumbs, setLastUpdated, title } = props
  const pathName = window.location.pathname

  useEffect(() => {
    if (breadcrumbs && t && baseNameLength && baseurl) {
      changeBreadcrumbs(breadcrumbs, t, pathName.slice(baseNameLength), baseurl)
    }
    if (setLastUpdated) {
      setLastUpdated()
    }
  }, [pathName, breadcrumbs, t, baseNameLength, setLastUpdated])

  return (
    <MicroFrontend
      disableContainerPadding
      {...props}
      title={title ? t(title) : title}
    />
  )
}

export default MfeWrapper
