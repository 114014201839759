import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PolicyHomeTab from 'components/PolicyHomeTab'
import MfeWrapper from 'components/MfeWrapper'
import {
  EDIT_SUFFIX,
  POLICIES_MFE,
  POLICIES_MFE_TYPE,
  POLICIES_PATH,
  DEVICES_SUFFIX,
  DEVICES_MFE,
  DEVICES_MFE_TYPE,
  HEADER_MFE,
  HEADER_MFE_TYPE,
  ASSIGNMENTS_SUFFIX,
  DEVICE_TAB_SCOPES,
  DEVICE_DETAILS_SCOPES,
  ASSIGNMENT_TAB_SCOPES
} from 'constants/constant'
import { Container, MainContent } from './styles'

const PolicyHomeMain = (props) => {
  const { navigation, customRelativePath, accessControl } = props
  const isWex = props?.themeIdentifier === 'wex'
  const baseurl =
    customRelativePath !== undefined ? customRelativePath : POLICIES_PATH

  const [showDevices, setShowDevices] = useState(true)
  const [showDevicesMfe, setShowDevicesMfe] = useState(isWex) // TO DO: isWex -> false
  const [showAssignments, setShowAssignments] = useState(true)

  const checkScopes = async () => {
    setShowDevices(await accessControl.checkScopes(DEVICE_TAB_SCOPES))
    setShowDevicesMfe(await accessControl.checkScopes(DEVICE_DETAILS_SCOPES))
    setShowAssignments(await accessControl.checkScopes(ASSIGNMENT_TAB_SCOPES))
  }

  useEffect(() => {
    // TO DO: remove !isWex
    if (accessControl && !isWex) {
      checkScopes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessControl])

  const [title, setTitle] = useState(null)
  const [lastUpdated, setLastUpdated] = useState(new Date()) // to render Header on tab change

  const baseName = navigation
    ? navigation.createHref({ pathname: baseurl })
    : baseurl

  const mainContent = useMemo(
    () => (
      <Router basename={baseName}>
        <Switch>
          {showDevicesMfe && (
            <Route path={`${DEVICES_SUFFIX}/*`}>
              <MfeWrapper
                setLastUpdated={() => setTitle('')}
                baseurl={baseurl}
                baseNameLength={baseName.length + DEVICES_SUFFIX.length}
                {...props}
                type={DEVICES_MFE_TYPE}
                component={DEVICES_MFE}
                customRelativePath={baseurl + DEVICES_SUFFIX}
              />
            </Route>
          )}
          <Route path={`${EDIT_SUFFIX}/*`}>
            <MfeWrapper
              setLastUpdated={() => setTitle('title.edit')}
              baseurl={baseurl}
              baseNameLength={baseName.length}
              {...props}
              type={POLICIES_MFE_TYPE}
              component={POLICIES_MFE}
              customRelativePath={baseurl}
            />
          </Route>
          <Route
            path={['/', `${DEVICES_SUFFIX}`, `${ASSIGNMENTS_SUFFIX}`]}
            exact
          >
            <PolicyHomeTab
              setLastUpdated={() => {
                setTitle(null)
                setLastUpdated(new Date())
              }}
              baseurl={baseurl}
              baseNameLength={baseName.length}
              showDevices={showDevices}
              showAssignments={showAssignments}
              {...props}
            />
          </Route>
          <Route path={'/*/*'} />
          <Route path={'/*'}>
            <MfeWrapper
              setLastUpdated={() => setTitle('')}
              baseurl={baseurl}
              baseNameLength={baseName.length}
              {...props}
              type={POLICIES_MFE_TYPE}
              component={POLICIES_MFE}
              customRelativePath={baseurl}
            />
          </Route>
        </Switch>
      </Router>
    ),
    [baseName, showDevicesMfe, showDevices, showAssignments, baseurl, props]
  )

  const header = useMemo(() => {
    return (
      <MfeWrapper
        data-testid={'policy-title-section'}
        title={title}
        {...props}
        type={HEADER_MFE_TYPE}
        component={HEADER_MFE}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, lastUpdated, props])

  return (
    <Container dir={'ltr'}>
      {!isWex && header}
      <MainContent data-testid={'policy-main-content'}>
        {mainContent}
      </MainContent>
    </Container>
  )
}

export default PolicyHomeMain
