import styled from 'styled-components'

export const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  max-width: 100%;

  & > * {
    width: 100%;
    max-width: 100%;
  }
`

export const CardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100px;
`

export const ErrorText = styled.p`
  font-size: 20px;
  font-weight: 700;
  margin-top: 16px;
`
